@import "~@/scss/vars.scss";

.footer-wrap {
  position: relative;
  .footer {
    background: rgba(0, 71, 187, 0.8);
    opacity: 0.75;
    height: 75px;
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
  }
}
