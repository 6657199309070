@import "~@/scss/vars.scss";

.contact {
  height: 800px;
  padding: 10px 275px;
  font-family: Open Sans;
  .scroll {
    height: 1px;
    width: 100%;
    position: relative;
    top: -150px;
  }
  h2 {
    font-family: Anton;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 70px;
    text-align: center;
    color: #0e2a59;
    margin-bottom: 160px;
    margin-bottom: 70px;
  }
  .contact-flex-container {
    display: flex;
    align-content: center;
    justify-content: center;
    .contact-flex-container-left {
      min-width: 600px;
      min-height: 400px;
      .company-info {
        position: relative;
        min-width: 250px;
        margin-bottom: 40px;
        padding: 10px 40px;
        h3 {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 27px;
          color: #606d80;
        }
        .company-info-icon {
          min-width: 30px;
          min-height: 30px;
          position: absolute;
          top: 50%;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-50%);
        }
        .details {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          color: #8892a1;
          span {
            font-weight: bold;
          }
          a {
            text-decoration: none;
            color: #8892a1;
          }
        }
      }
    }
    .contact-flex-container-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .input-wrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        margin-top: 5px;
        label {
          font-family: Open Sans;
          font-style: italic;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #8892a1;
        }
        input {
          background: #ffffff;
          border: 1px solid #e2e2e2;
          box-sizing: border-box;
          width: 512px;
          height: 42px;
          padding: 12px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #8892a1;
          transition: all 0.1s;
          &:focus {
            outline: 2px solid #8892a1;
          }
        }
      }
      textarea {
        width: 512px;
        height: 106px;
        background: #ffffff;
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        padding: 12px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #8892a1;
        transition: all 0.1s;
        resize: none;
        &:focus {
          outline: 2px solid #8892a1;
        }
      }
      .error-msg {
        width: 100%;
        text-align: right;
        font-size: 12px;
        color: #df2d2d;
        font-style: italic;
      }
      .success-msg {
        width: 100%;
        text-align: right;
        font-size: 12px;
        color: #4ceb37;
        font-style: italic;
      }
    }
    button {
      width: 200px;
      height: 67px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.1em;
      color: #ffffff;
      background: $textOrange;
      border: none;
      cursor: pointer;
      margin-left: calc(100% - 200px);
      margin-top: 40px;
      &.disabledBtn {
        background: #cfc8c0;
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .contact {
    height: unset;
    padding: 50px 0;
    .contact-flex-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h2 {
      margin-bottom: 30px;
    }
  }
}
@media only screen and (max-width: 700px) {
  .contact-flex-container-left {
    width: unset;
    height: unset;
    .company-info {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      flex-direction: column !important;
      margin: 40px auto !important;
      max-width: 250px !important;
      .details {
        width: 100%;
      }
      h3 {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  button {
    margin-left: 0 !important;
  }
  input {
    width: unset !important;
  }
  textarea {
    width: 100% !important;
  }
}
