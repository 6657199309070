@import "~@/scss/vars.scss";

.nav {
  max-width: 1905px;
  position: relative;
  font-family: "Open Sans", sans-serif;
  height: 100px;
  width: 100%;
  background: #fff;
  padding: 20px 190px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  .material-icons {
    display: none;
  }
  .nav-items-mobile {
      display: none;
  }  
  .nav-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    min-width: 650px;
    a {
      cursor: pointer;
      text-decoration: none;
      color: $textBlue;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      transition: all 0.1s ease-in-out;
      &:hover {
        color: $textOrange;
      }
    }
  }
}
.fixed {
  position: fixed;
  opacity: 0.95;
  box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .nav {
    height:60px;
    padding-left: 30px;
    padding-right: 30px;
    img{
      display: block;
      height:200%
    }
    .material-icons {
      display: block;
      cursor: pointer;
    }
    .nav-items {
      display: none;
    }
    .nav-items-mobile {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      background: #fff;
      width: 100%;
      z-index: 1000;
      left: 0;
      padding: 50px;
      box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.3);
      a {
        cursor: pointer;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        text-transform: capitalize;
        text-decoration: none;
        color: $textOrange;
      }
    }
  }
}
