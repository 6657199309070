@import "~@/scss/vars.scss";

.customs-export-import {
  .scroll {
    position: relative;
    top: -150px;
  }
  padding: 70px 190px;
  background: #f8f8f8;
  h2 {
    text-align: center;
    font-family: Anton;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 70px;
    color: $textBlue;
    margin: 50px;
  }
  p {
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 200%;
    text-align: center;
    color: #8f9aac;
    width: 100%;
    margin: 0 auto;
  }
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
    .flex-item {
      height: 460px;
      box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
      h3 {
        font-family: Anton;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 38px;
        text-align: center;
        margin-top: 40px;
        color: $textBlue;
      }
    }
  }
}
@media only screen and (max-width: 1800px) {
  .customs-export-import {
    // padding: 95px 90px !important;
    .flex-container {
      justify-content: space-around;
      .flex-item {
        transform: scale(0.7);
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .customs-export-import {
    padding: 45px 30px !important;
    p {
      width: 100%;
    }
    .flex-container {
      flex-direction: column;
      .flex-item {
        margin: 30px 0;
        transform: scale(1);
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .flex-container {
    margin: 0 !important;
    padding: 80px 0 0 0;
    .flex-item {
      transform: scale(0.8) !important;
      margin: 0 !important;
    }
  }
}
@media only screen and (max-width: 400px) {
  .customs-export-import {
    padding: 0 30px;
    .flex-container {
      .flex-item {
        transform: scale(0.65) !important;
      }
    }
  }
}
