@import "~@/scss/vars.scss";

.petikom-enterprise {
  min-height: 300px;
  position: relative;
  padding: 230px 190px 100px 190px;
  .contact-adress {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateX(-50%);
    left: 50%;
    top: -70px;
    .contact-1,
    .contact-2,
    .adress {
      position: relative;
      width: 370px;
      height: 121px;
      background: #ffffff;
      box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06);
      margin: 25px;
      padding: 25px 75px;
      transition: all 0.1s ease-in-out;
      a {
        text-decoration: none;
        color: #0e2a59;
      }
      h2 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: $textGray;
      }
      div {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 33px;
        color: #0e2a59;
        margin-top: 10px;
      }
      .icon-phone {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 10px;
        top: 50%;
        margin: 0;
        transform: translateY(-50%);
        background: url("../assets/orange_phone.svg") center center no-repeat;
      }
      .icon-location {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 10px;
        top: 50%;
        margin: 0;
        transform: translateY(-50%);
        background: url("../assets/orange_location.svg") center center no-repeat;
      }
      &:hover {
        background: $textOrange;

        h2,
        div,a {
          color: #fff;
        }
        .icon-phone {
          background: url("../assets/white_phone.svg") center center no-repeat;
        }
        .icon-location {
          background: url("../assets/white_location.svg") center center
            no-repeat;
        }
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    .img-content {
    }
    .text-content {
      margin-right: 50px;
      h2 {
        font-family: Anton;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 70px;
        color: $textBlue;
        margin-bottom: 50px;
      }
      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: light;
        font-size: 18px;
        line-height: 200%;
        color: $textGray;
        span {
          color: $textBlue;
          font-weight: bold;
        }
      }
    }
  }
}
@media only screen and (max-width: 1580px) {
  .petikom-enterprise {
    padding: 230px 85px;
  }
}
@media only screen and (max-width: 1280px) {
  .petikom-enterprise {
    padding: 45px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .contact-adress {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      min-height: 400px !important;
      margin: 0 !important;
      position: static;
      transform: none;
    }
    .content {
      flex-direction: column-reverse;
      .img-content {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .text-content {
        margin: 0;
        h2 {
          text-align: center;
          margin-top: 20px;
        }
        p {
          text-align: center;
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
}
@media only screen and (max-width: 500px) {
  .contact-adress {
    .contact-1,
    .contact-2,
    .adress {
      width: 350px !important;
      padding: 15px 75px !important;
    }
  }
}
