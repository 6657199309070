@import "~@/scss/vars.scss";

section {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  h2 {
    top: 8%;
    font-family: Anton;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 70px;
    text-align: center;
    color: #ffffff;
    position: absolute;
    z-index: 999;
  }
  div.container {
    transition: all 0.3s ease;
    background-image: url("../assets/partners.png") !important;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    h1 {
      margin: 15px 0 0 0;
    }
    h3 {
      margin: 0 0 25px 0;
    }
  }
  @media (max-width: 992px) {
    // padding: 0 20px 0 20px;
  }
}
section.slide-option {
  margin: 0 0 50px 0;
  .no-marg {
    margin: 0 0 0 0;
  }
}

.highway-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 435px;
  .highway-barrier {
    overflow: hidden;
    position: relative;
  }
  .highway-lane {
    display: flex;
    height: 100%;
    .highway-car {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #343434;
      img {
        display: inline-block;
        width: 100%;
      }
    }
  }
}

@keyframes translatestf {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-500%);
  }
}
#stffull div.highway-barrier {
  .highway-lane {
    width: 500%;
    .highway-car {
      animation: translatestf 30s linear infinite;
      h4 {
        font-size: 28px;
      }
    }
  }
}

@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-270px * 24));
  }
}
#infinite div.highway-barrier {
  background: #fff;
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.3);

  .highway-lane {
    width: calc(540px * 24);
    .highway-car {
      width: 500px;
      animation: translateinfinite 60s linear infinite;
      img {
        display: block;
        width: 80%;
      }
      .fab {
        font-size: 65px;
      }
    }
  }
}

#red {
  background: #cb5454;
}
#orange {
  background: #cb8054;
}
#yellow {
  background: #cbba54;
}
#green {
  background: #69b46e;
}
#blue {
  background: #6091b0;
}
