@import "~@/scss/vars.scss";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.wrapper {
  max-width: 1905px;
  margin: 0 auto;
  overflow: hidden;
}
