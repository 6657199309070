@import "~@/scss/vars.scss";

.truck-cover-component {
  height: 461px;
  background: url("../assets/truck-cover.png") center center no-repeat;
  background-size: cover;
  padding: 70px 190px;
  h2 {
    font-family: Anton;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 67px;
    color: #fff;
    width: 900px;
      // max-width: 60%;
  }
  p {
    font-family: Open Sans;
    font-style: italic;
    font-weight: 600;
    font-size: 24px;
    line-height: 200%;
    color: $textGray;
    margin: 15px 0 50px 0;
      max-width: 55%;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 200px;
    height: 67px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #ffffff;
    background: $textOrange;
    border: none;
    cursor: pointer;
      max-width: 60%;
  }
}
@media only screen and (max-width: 1280px) {
  .truck-cover-component {
    background: #0047bb;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      text-align: center;
    }
    p {
    }
  }
}
@media only screen and (max-width: 880px) {
  .truck-cover-component {
    height: unset;
    h2 {
      font-family: Anton;
      font-style: normal;
      font-weight: normal;
      font-size: 28px !important;
      color: #fff;
      width: 100% !important;
    }
    p {
      text-align: center;
    }
  }
}
